export default [
  //
  //* ——— Reporting ——————————————————
  //

  {
    path: '/reporting/merchant-sales-report',
    name: 'merchant-sales-report',
    component: () => import('@/modules/report/views/merchant-sales-report/MerchantSalesOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-voucher-report',
    name: 'merchant-vouchers-report',
    component: () => import('@/modules/report/views/merchant-vouchers-report/MerchantVoucherOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-service-report',
    name: 'merchant-services-report',
    component: () => import('@/modules/report/views/merchant-service-report/MerchantServiceOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-tangible-report',
    name: 'merchant-tangibles-report',
    component: () => import('@/modules/report/views/merchant-tangible-report/MerchantTangibleOverview.vue'),
    meta: {
      layout: 'content',
    },
  },
]
