const merchant = [
  // {
  //   path: '/merchants',
  //   name: 'list-merchants',
  //   component: () => import('@/modules/merchant/views/merchant-list/MerchantList.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },

  // {
  //   path: '/merchants/create',
  //   name: 'create-merchant',
  //   component: () => import('@/modules/merchant/components/merchant/Create.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },

  {
    path: '/merchant/overview',
    name: 'merchant-view',
    component: () => import('@/modules/merchant/views/merchant-view/MerchantView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default merchant
