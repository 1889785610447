import Vue from 'vue'

const pages = [
  // ? We are redirecting to different app based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const isAccessTokenExist = Vue.$cookies.get('access_token') !== null

      if (!isAccessTokenExist) {
        return {
          name: 'auth-login',
          query: to.query,
        }
      }

      return {
        name: 'auth-user',
        query: to.query,
      }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/modules/app/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/modules/app/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  // Get user profile & ability and update ability for access control
  // User is logged in when access token was stored in cookie
  // Need to perform this action when it is the first time login or page refreshed
  {
    path: '/authenticate',
    name: 'auth-user',
    component: () => import('@/modules/app/views/Authenticate.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/modules/app/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/modules/app/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/modules/app/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/modules/app/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default pages
