import axios from '@axios'

// --- SKU ---

const fetchSkus = params => axios.get('/merchant/skus', { params })

// const storeSku = data => axios.post('/merchant/skus', data)

const updateSku = (skuId, data) => axios.put(`/merchant/skus/${skuId}`, data)

// const destroySku = skuId => axios.delete(`/merchant/skus/${skuId}`)

// const handleSkuStatus = (id, action = 'publish') => axios.post(`/merchant/skus/${id}/${action}`)

// const storeSkuBoilerplate = skuId => axios.post(`/merchant/skus/${skuId}/boilerplate`)

// --- Slot Rules ---

const fetchSkuSlotRules = queryParams => axios.get('/merchant/slot-rules', { params: queryParams })

// const storeSlotRule = data => axios.post('/merchant/slot-rules', data)

// const updateSlotRule = (id, data) => axios.put(`/merchant/slot-rules/${id}`, data)

// const destroySlotRule = id => axios.delete(`/merchant/slot-rules/${id}`)

// --- Slots ---

const fetchSkuSlots = queryParams => axios.get('/merchant/sku-time-slots', { params: queryParams })

// const storeSlot = data => axios.post('/merchant/sku-time-slots', data)

// const storeSlotsFromRule = data => axios.post('/merchant/sku-time-slots/from-rule', data)

// const updateSlot = (id, data) => axios.put(`/merchant/sku-time-slots/${id}`, data)

// const destroySlot = id => axios.delete(`/merchant/sku-time-slots/${id}`)

// --- Voucher Groups ---

const fetchVoucherGroups = queryParams => axios.get('/merchant/voucher-groups', { params: queryParams })

// const storeVoucherGroup = data => axios.post('/merchant/voucher-groups', data)

// const updateVoucherGroup = (id, data) => axios.put(`/merchant/voucher-groups/${id}`, data)

// const destroyVoucherGroup = id => axios.delete(`/merchant/voucher-groups/${id}`)

// --- Slots ---

const fetchVouchers = queryParams => axios.get('/merchant/vouchers', { params: queryParams })

// const storeVouchersFromGroup = data => axios.post('/merchant/vouchers/from-group', data)

// const updateVoucher = (id, data) => axios.put(`/merchant/vouchers/${id}`, data)

// const destroyVoucher = id => axios.delete(`/merchant/vouchers/${id}`)

// --- Photo ---

// const storePhoto = data => axios.post('/merchant/product-media/photos', data)

// const updatePhoto = (id, data) => axios.put(`/merchant/product-media/${id}`, data)

// const destroyPhoto = id => axios.delete(`/merchant/product-media/${id}`)

// --- Product ---

// const storeProduct = data => axios.post('/merchant/products', data)

const updateProduct = (productId, data) => axios.put(`/merchant/products/${productId}`, data)

// const destroyProduct = productId => axios.delete(`/merchant/products/${productId}`)

const fetchProducts = queryParams => axios.get('/merchant/products', { params: queryParams })

const fetchProduct = id => axios.get(`/merchant/products/${id}`)

// const handleProductStatus = (id, action = 'publish') => axios.post(`/merchant/products/${id}/${action}`)

// const replicateProduct = productId => axios.post(`/merchant/products/${productId}/replicate`)

// --- Price ---

const fetchSkuPriceGroups = queryParams => axios.get('/merchant/price-groups', { params: queryParams })

// const storePriceGroup = data => axios.post('/merchant/price-groups', data)

// const updatePriceGroup = (id, data) => axios.put(`/merchant/price-groups/${id}`, data)

// const storePrice = data => axios.post('/merchant/prices', data)

// const updatePrice = (priceId, data) => axios.put(`/merchant/prices/${priceId}`, data)

// --- channel price ---
// (no update, just store will handle update)
// const storeChannelPrice = data => axios.post('/merchant/channel-prices', data)

// --- attribute type ---

const fetchAllAttributeTypes = queryParams => axios.get('/merchant/attribute-types', { params: queryParams })

// const storeAttributeType = data => axios.post('/merchant/attribute-types', data)

// const updateAttributeType = (id, data) => axios.put(`/merchant/attribute-types/${id}`, data)

// const destroyAttributeType = id => axios.delete(`/merchant/attribute-types/${id}`)

// --- attribute value ---

const fetchAllAttributeValues = queryParams => axios.get('/merchant/attribute-values', { params: queryParams })

// const storeAttributeValue = data => axios.post('/merchant/attribute-values', data)

// const updateAttributeValue = (id, data) => axios.put(`/merchant/attribute-values/${id}`, data)

// const destroyAttributeValue = id => axios.delete(`/merchant/attribute-values/${id}`)

// --- Bulk Upload ---

// const spreadSheetUpload = formData => axios.post('/merchant/products/spreadsheet-upload', formData)

// --- Brand ---

// const fetchBrands = queryParams => axios.get('/merchant/brands', { params: queryParams })

// const storeBrand = data => axios.post('/merchant/brands', data)

// const updateBrand = (id, data) => axios.put(`/merchant/brands/${id}`, data)

// const destroyBrand = id => axios.delete(`/merchant/brands/${id}`)

// const storeBrandMedia = data => axios.post('/merchant/brand-media/photos', data)

export {
  // storePriceGroup,
  // updatePriceGroup,
  // storePrice,
  // updatePrice,
  // storeChannelPrice,
  // storePhoto,
  // updatePhoto,
  // destroyPhoto,
  fetchAllAttributeTypes,
  // storeAttributeType,
  // updateAttributeType,
  // destroyAttributeType,
  fetchAllAttributeValues,
  fetchProduct,
  fetchProducts,
  // storeVouchersFromGroup,
  // updateVoucher,
  // destroyVoucher,
  fetchSkuPriceGroups,
  // destroySku,
  // handleSkuStatus,
  // storeSkuBoilerplate,
  fetchSkuSlotRules,
  // storeSlotRule,
  // updateSlotRule,
  // destroySlotRule,
  fetchSkuSlots,
  // handleProductStatus,
  // replicateProduct,
  // storeSku,
  fetchSkus,
  // storeSlot,
  // storeSlotsFromRule,
  // updateSlot,
  // destroySlot,
  fetchVoucherGroups,
  // storeVoucherGroup,
  // updateVoucherGroup,
  // destroyVoucherGroup,
  fetchVouchers,
  updateProduct,
  updateSku,
}
