export default [
  {
    path: '/products',
    name: 'product-list',
    component: () => import('@/modules/product/views/product-list/ProductList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/products/:id/overview',
    name: 'product-view',
    component: () => import('@/modules/product/views/product-view/ProductView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/product-update',
    name: 'product-update-resource',
    component: () => import('@/modules/product/views/product-resource/ProductUpdate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/skus',
    name: 'sku-list',
    component: () => import('@/modules/product/views/sku-list/SkuList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
