const checkout = [
  {
    path: '/checkout/checkout-list',
    name: 'checkout-list',
    component: () => import('@/modules/order/views/checkout-list/CheckoutList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/checkout-view/:id',
    name: 'checkout-view',
    component: () => import('@/modules/order/views/checkout-view/CheckoutView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/order/view/:id',
    name: 'checkout-order-view',
    component: () => import('@/modules/order/views/order-view/OrderView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/receipt-preview/:id',
    name: 'checkout-receipt-preview',
    component: () => import('@/modules/order/views/receipt-preview/ReceiptPreview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/refund-request-list',
    name: 'refund-request-list',
    component: () => import('@/modules/order/views/refund-request-list/RefundRequestList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('@/modules/order/views/order-list/OrderList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orders/:id/overview',
    name: 'order-view',
    component: () => import('@/modules/order/views/order-view/OrderView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default checkout
