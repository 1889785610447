const dashboard = [
  {
    path: '/dashboards/crm',
    name: 'dashboard-crm',
    component: () => import('@/modules/dashboard/views/Crm.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard
