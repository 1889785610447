import appointment from '@/modules/appointment/routes'
import dashboard from '@/modules/dashboard/routes'
import merchant from '@/modules/merchant/routes'
import order from '@/modules/order/routes'
import product from '@/modules/product/routes'
import reporting from '@/modules/report/routes'
import { canNavigate } from '@/plugins/acl/routeProtection'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import app from './modules/app/routes'

Vue.use(VueRouter)

const routes = [
  ...app,
  ...dashboard,
  ...merchant,
  ...order,
  ...appointment,
  ...product,
  ...reporting,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',

  // base: config.api.baseUrl,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const isAccessTokenExist = Vue.$cookies.get('access_token') !== null
  const isUserAbilitySet = store.getters['user/getAbility'] !== null

  // Non public routes
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isAccessTokenExist) {
      return next({
        name: 'auth-login',
        query: {
          // marketplace: to.query.marketplace,
          redirect: to.fullPath,
        },
      })
    }

    // Redirect user to authenticate page if user ability is not set in vuex store
    if (!isUserAbilitySet) {
      return next({
        name: 'auth-user',
        query: {
          redirect: to.fullPath,
        },
      })
    }

    // Eles, not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (isAccessTokenExist && to.meta.redirectIfLoggedIn) {
    return next('/')
  }

  return next()
})

export default router
